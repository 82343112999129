.home-active {
  width: 0.586667rem;
  height: 0.533333rem;
  display: inline-block;
  background: url(../images/home-active.png) no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.106667rem;
}

.laba-icon {
  background: url(../images/laba.png) no-repeat;
  background-size: 100% 100%;
  width: 0.48rem;
  height: 0.373333rem;
  display: inline-block;
}

.finished-icon {
  background: url(../images/finished.png) no-repeat;
  background-size: 100% 100%;
  width: 1.333333rem;
  height: 1.346667rem;
  display: inline-block;
}